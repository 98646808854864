
































































































































import { Component, Vue } from 'vue-property-decorator'

type ValidationResult = string | boolean
type ValidationFunction = () =>  ValidationResult
@Component({
    components: {
    }

})
export default class RegisterUser extends Vue {
    form: any = {
            email: "",
            password1: "",
            password2: "",
            terms: false
        }
    show1 = false
    show2 = false

    specialCharacters = '`!@#$%^&*()_+-=[]{};:|,.<>/?~'

    token = ''
    errorMessages = ''
    formHasErrors = false

    isRequired = (prop: string): ValidationFunction => 
        (): ValidationResult => !!prop || "Dit veld is vereist"

    checkEquals = (): ValidationResult => this.form.password1 == this.form.password2 || "Wachtwoorden moeten overeenkomen"

    checkNumerical = (prop: string): ValidationFunction => 
        (): ValidationResult => /[0-9]/.test(prop) || "Wachtwoord moet minimaal 1 getal bevatten"

    checkUpperAlphabetical = (prop: string): ValidationFunction => 
        (): ValidationResult => /[A-Z]+/.test(prop) || "Wachtwoord moet minimaal 1 hoofdletter bevatten"

    checkLowerAlphabetical = (prop: string) => 
        (): ValidationResult => /[a-z]+/.test(prop) || "Wachtwoord moet minimaal 1 kleine letter bevatten"

    checkSymbols = (prop: string): ValidationFunction => 
        // (): ValidationResult => /[ `!@#$%^&*\(\)_+-=\[\]\{\};:|,.<>/?~]/.test(prop) || "Wachtwoord moet minimaal 1 speciaal karakter bevatten"
        (): ValidationResult => /[ `!@#$%^&*()_+-=[\]{};:|,.<>/?~]/.test(prop) || "Wachtwoord moet minimaal 1 speciaal karakter bevatten"

    checkLength = (prop: string): ValidationFunction => 
        (): ValidationResult => prop.length > 16 || "Wachtwoord moet minimaal 16 karakters bevatten"

    register(): void {
        this.formHasErrors = false
        Object.keys(this.form).forEach((f: string)=> {
          if (!this.form[f]) this.formHasErrors = true

            const ref : any = this.$refs[f]
            ref.validate(true)
        })

        if (!this.formHasErrors) {
            
            this.$store.dispatch('identity/confirmRegistration', { 
                email: this.form.email,
                token: this.token,
                password: this.form.password1
                })
        }
    }

  mounted(): void {
      this.form.email = (this.$route.query["email"] ?? "").toString()
      this.token = (this.$route.query["token"] ?? "").toString()
  }
}
